import Footer from "../components/footer";
import Header from "../components/header";

const About = () => {
    let photos = [
        {
            url:'',
            name:'car'
        },
        {
            url:'',
            name:'flowers'
        },
    ]
    function search(){
        let input = document.getElementById('').value;
        let result = photos.filter((i)=> i.name == input);
        if(result.length > 0){
            let text = '';
            for(let i = 0;i<photos.length;i++){
                text += ``;
            }
            document.getElementById('').innerHTML = text;
        }else{
            document.getElementById('').innerHTML = 'No result';
        }
    }
    return (
        <>
            <Header />
            <div className="col-12">
                <div className="row">
                    <div className="col-12 mt-3 bg-light">
                        “UNITY” (биримдик) компаниясынын турак жай менен тейлөө тартиби 
                        Кыргыз Республикасынын аймагынын ичинде гана уюштурулуп, 
                        жана жарандары менен гана чектелген комерциялык эмес кооперативтик уюм. 
                        Кооперативтик башка компаниялардан уставдык эреже схемасы жана талаптары менен айырмаланат.
                        Компаниянын уюштуруучулары жана мүчөлөрү болуп эки тараптуу иш жүргүзүлөт. 
                        Жөнөкөй жаран мүчөлүкө катталуу үчүн бир жолку төлөм жүргүзүү зарыл. 
                        Көпчүлүктүн мына ушундай бир жолку төлөмдөрүнүн негизинде мүчөлөрдүн каржылык абалына карап, турак жай менен тейленет. 
                        Бул процесс биздин платформада онлайн көрсөтүлөт. Компания өз мүчөлөрүн тейлөө менен гана иш алып барат. 
                        Каттоого алынган адам тизмеге кошулуп, компания жетекчилиги тарабынан тастыкталган (номерлүү) күбөлүгү менен кооперативтин мүчөсү болуп эсептелет. 
                        Жөнөкөй жаран турак жайлуу болуу максаттан тышкары, колдоо көрсөтү же уюштуруучулардын катарына кошулуп жетекчиликке талапкер болуу максатта кошулса болот. 
                        Кыргыз Республикасынын жарандыгы экенин тастыктаган күбөлүгү(паспорт) бар адам мүчө боло алат. 
                        Компаниянын жетекчиси Президент аталып уюштуруучулар тарабынан бир жылдык мөөнөткө шайланат, жана ар бир уюштуруучу жетекчи болууга талапкер боло алат. 
                        Уюштуруучулардын жүз пайыз добушу менен жетекчи шайлана алат. Эгерде кимдер бирөөнү уюштуруучулуктун курамынан чететтүү максат каралган болсо анда жүз пайыз уюштуруучулардын добушу менен чететтилет. 
                        Компаниянын жетекчиси таттыктуулугу менен кийинки жылдарга да улантылып шайланууга акысы бар. 
                        Компаниянын мүчөлөрү уюштуруучулардын катарына кошулуу үчүн Компанияга кеминде 10 адамдын мүчө болуп катталуусуна себепкер болушу керек. 
                        Уюштуруучулардын мүчөлөрдөн айырмаланып компаниянын жоопкерчилиги жүктөлгөн. 
                        Башталышында канчалык жарандардын катталуусу көп болсо ошончолук тейлөө тез болуп компаниянын иш жүргүзүү процеси күчөйт. 
                        Негизги максат принципке ылайык жарандарыбыздын турак жайды ижарага алып жашагандардын санын кыскартуу болуп эсептелет. 
                        Жарандардын бул компанияга катталуусу менен турак жайды ижарага берүү, ижарага алуу, ижарадан пайда көрүү максатагы адамдардын саны кыскарат. 
                        Бул нерсе Кыргыз Республикасынын аймагындагы турак жайсыз жана турак жайлуу жарандардын ортосундагы ажырымды жоюууга тең салмактуулугун сактап, адилеттүү тейлөө тартибин ишке ашырат. 
                        Компаниянын биринчи уюштуруучулары компаниянын негиздөөчүлөрү болуп эсептелет.
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default About;